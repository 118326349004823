import * as React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

// Components
import Title from "../../components/Title";
import Navbar from "../../components/Navbar";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SubModules from "../../components/list/SubModules";
import Breadcrumbs from "../../components/Breadcrumbs";

// Assets
import hrmImg from "../../assets/modules/hrm.png";

const modulesPosts = [
  {
    id: 1,
    img: hrmImg,
    title: "Zona Anda",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    link: "/hrm/detail",
  },
  {
    id: 2,
    img: hrmImg,
    title: "HR Department",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    link: "/hrm/detail",
  },
  {
    id: 3,
    img: hrmImg,
    title: "Absensi",
    date: "Nov 12",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    link: "/hrm/detail",
  },
  {
    id: 4,
    img: hrmImg,
    title: "Time Off",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    link: "/hrm/detail",
  },
  {
    id: 5,
    img: hrmImg,
    title: "Perjalanan Dinas",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    link: "/hrm/detail",
  },
  {
    id: 6,
    img: hrmImg,
    title: "Kinerja",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    link: "/hrm/detail",
  },
  {
    id: 7,
    img: hrmImg,
    title: "Tasking",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    link: "/hrm/detail",
  },
];

const breadcrumbsData = [
  {
    id: 1,
    title: "Beranda",
    link: "/",
    isActive: false,
  },
  {
    id: 2,
    title: "Human Resource Management",
    link: "/hrm",
    isActive: true,
  },
];

export default function Index() {
  return (
    <div>
      <Container maxWidth="xl">
        <Navbar />
        <main>
          <Header isLogin={true} />
          <Grid container spacing={4} sx={{ mt: 2 }}>
            <Grid item xs={12} md={12}>
              <Breadcrumbs data={breadcrumbsData} />
              <Title data={"Modul Human Resource Management"} />
              <Grid container spacing={4} sx={{ mt: 2 }}>
                {modulesPosts.map((post) => (
                  <SubModules key={post.id} post={post} />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </main>
      </Container>
      <Footer />
    </div>
  );
}
