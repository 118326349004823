import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function ActiveLastBreadcrumb(props) {
  const { data } = props;

  return (
    <div onClick={handleClick} style={{ marginBottom: 5 }}>
      <Breadcrumbs aria-label="breadcrumb">
        {data.map((data) => (
          <Link
            key={data.id}
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={data?.link}
            fontWeight={data?.isActive ? "bold" : "normal"}
            sx={{ fontSize: "small" }}
          >
            {data?.title}
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  );
}
