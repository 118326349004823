import * as React from "react";
import Grid from "@mui/material/Grid";
import SubModulesCard from "../cards/SubModules";

function SubModules(props) {
  const { post } = props;

  return (
    <Grid item xs={12} md={4}>
      <SubModulesCard data={post} />
    </Grid>
  );
}

export default SubModules;
