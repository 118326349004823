import React, { Fragment } from "react";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// Assets
import logoImg from "../assets/logo.png";

function Header() {
  return (
    <Fragment>
      <Toolbar
        sx={{ mb: 4, mt: 2, pl: "unset !important", pr: "unset !important" }}
      >
        <Grid container>
          <Grid item xs={6} md={6} sx={{ alignSelf: "center" }}>
            <a href="/">
              <img src={logoImg} alt="" height="80" />
            </a>
          </Grid>
          <Grid item xs={6} md={6} sx={{ alignSelf: "center" }}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="outlined" size="small">
                Login
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </Fragment>
  );
}

export default Header;
