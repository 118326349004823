import * as React from "react";
import Grid from "@mui/material/Grid";
import ModulesCard from "../cards/Modules";

function Modules(props) {
  const { post } = props;

  return (
    <Grid item xs={12} md={6}>
      <ModulesCard data={post} />
    </Grid>
  );
}

export default Modules;
