import * as React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

// Components
import Title from "./Title";
import Information from "./list/Information";

function Sidebar(props) {
  const { information, description } = props;

  return (
    <Grid item xs={12} md={12}>
      <Title data={"Tentang Kami"} />
      <Paper elevation={0} sx={{ p: 2, bgcolor: "grey.200", mb: 3 }}>
        <Typography>{description}</Typography>
      </Paper>
      <Title data={"Sistem Terkait"} />
      <Grid container spacing={2}>
        {information.map((data) => (
          <Information key={data.id} data={data} />
        ))}
      </Grid>
    </Grid>
  );
}

export default Sidebar;
