import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Pages
import Index from "./pages/Index";
import HRM from "./pages/HRM/Index";
import HRMDetail from "./pages/HRM/Detail";

const theme = createTheme({
  typography: {
    fontFamily: `'Ubuntu', sans-serif`,
    fontWeightRegular: 400,
    fontWeightBold: 500,
    letterSpacing: "-0.5px",
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/hrm" element={<HRM />} />
          <Route path="/hrm/detail" element={<HRMDetail />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
