import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

export default function ImgMediaCard(props) {
  const { data } = props;

  const navigate = useNavigate();

  return (
    <Card
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
      }}
    >
      <CardMedia
        component="img"
        alt="green iguana"
        height="280"
        image={data?.img}
      />
      <CardContent>
        <Typography gutterBottom variant="h6" fontWeight="bold">
          {data?.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Lizards are a widespread group of squamate reptiles, with over 6,000
          species, ranging across all continents except Antarctica
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          sx={{
            textTransform: "none",
          }}
          size="small"
          onClick={() => navigate(data?.link)}
        >
          Selengkapnya
        </Button>
      </CardActions>
    </Card>
  );
}
