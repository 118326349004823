import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography, Box, Link } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

// Material Icons
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

function ModuleList({ ...props }) {
  return (
    <Grid item xs={12} sx={{ ml: 2 }}>
      <Link
        href="#"
        underline="none"
        sx={{
          color: "#000000",
          textTransform: "none",
          "&:hover": {
            color: "#0063ab",
          },
        }}
      >
        <div style={{ display: "flex" }}>
          <InsertDriveFileOutlinedIcon sx={{ fontSize: 30 }} />
          <div style={{ marginLeft: 10 }}>
            <Typography variant="subtitle1">{props.name}</Typography>
          </div>
        </div>
      </Link>
    </Grid>
  );
}

export default function Contents() {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.up("sm"));

  const modules = [
    { id: 1, name: "Persuratan" },
    { id: 2, name: "Manajemen Absensi" },
    { id: 3, name: "Administrasi Pegawai" },
    { id: 4, name: "Manajemen Kinerja" },
    { id: 5, name: "Penggajian" },
  ];

  return (
    <Box sx={{ p: isMobileView ? 5 : 2, pt: isMobileView ? 0 : 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3" fontWeight="bold">
            Zona Anda
          </Typography>
          <Typography variant="body2" sx={{ mt: 0.5 }} gutterBottom>
            Update by: Administrator
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Berikut merupakan Dokumentasi dari Zona Anda:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
            List Modul
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1.5}>
            {modules.map((data) => (
              <ModuleList key={data.id} name={data.name} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
