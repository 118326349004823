import * as React from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Typography, IconButton } from "@mui/material";

// Material Icon
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";

function SidebarContent({ ...props }) {
  const { collapsed, setCollapsed } = props;

  const handleOpenSidebar = () => {
    setCollapsed(false);
  };

  const handleCloseSidebar = () => {
    setCollapsed(true);
  };

  return (
    <div>
      <div
        style={{
          display: collapsed ? "none" : "block",
          fontFamily: `'Ubuntu', sans-serif`,
          letterSpacing: "-0.3px",
        }}
      >
        <Sidebar
          collapsed={collapsed}
          width="unset"
          style={{ border: "unset" }}
        >
          <Menu>
            <div
              style={{
                paddingTop: "10px",
                paddingLeft: "20px",
                paddingBottom: "10px",
                display: "flex",
              }}
            >
              <div style={{ alignContent: "center", width: "100%" }}>
                <Typography
                  variant="body1"
                  fontWeight={600}
                  style={{
                    opacity: collapsed ? 0 : 0.7,
                    letterSpacing: "0.5px",
                  }}
                >
                  List Modul
                </Typography>
              </div>
              <div>
                <IconButton
                  color="primary"
                  aria-label="Close Sidebar"
                  onClick={handleCloseSidebar}
                >
                  <ChevronLeftIcon />
                </IconButton>
              </div>
            </div>
            <MenuItem style={{ fontSize: 15 }} icon={<FolderOutlinedIcon />}>
              Persuratan
            </MenuItem>
            <SubMenu
              style={{ fontSize: 15 }}
              label="Manajemen Absensi"
              icon={<FolderOutlinedIcon />}
            >
              <MenuItem style={{ fontSize: 15 }}>Rekap Absensi</MenuItem>
              <MenuItem style={{ fontSize: 15 }}>Cuti Pegawai</MenuItem>
            </SubMenu>
            <SubMenu
              style={{ fontSize: 15 }}
              label="Administrasi Pegawai"
              icon={<FolderOutlinedIcon />}
            >
              <MenuItem style={{ fontSize: 15 }}>Rekap Absensi</MenuItem>
              <MenuItem style={{ fontSize: 15 }}>Cuti Pegawai</MenuItem>
            </SubMenu>
            <SubMenu
              style={{ fontSize: 15 }}
              label="Manajemen Kinerja"
              icon={<FolderOutlinedIcon />}
            >
              <MenuItem style={{ fontSize: 15 }}>Rekap Absensi</MenuItem>
              <MenuItem style={{ fontSize: 15 }}>Cuti Pegawai</MenuItem>
            </SubMenu>
            <MenuItem style={{ fontSize: 15 }} icon={<FolderOutlinedIcon />}>
              Penggajian
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
      {collapsed ? (
        <IconButton
          color="primary"
          aria-label="Open Sidebar"
          onClick={handleOpenSidebar}
        >
          <ChevronRightIcon />
        </IconButton>
      ) : null}
    </div>
  );
}

export default SidebarContent;
