import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

function Footer() {
  return (
    <Box component="footer" sx={{ bgcolor: "#1D2F53", py: 2, mt: 10 }}>
      <Container maxWidth="xl">
        <Typography
          variant="body2"
          color="#ffff"
          align="center"
          sx={{ fontSize: "12px" }}
        >
          ©{new Date().getFullYear()} - PERUMDA PARKIR MAKASSAR RAYA | Developed
          & Maintenance By : PT. iLugroup Multimedia Indonesia - All Rights
          Reserved!
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
