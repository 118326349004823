import * as React from "react";
import Grid from "@mui/material/Grid";
import InformationCard from "../cards/Information";

function Information(props) {
  const { data } = props;

  return (
    <Grid item xs={12} md={6}>
      <InformationCard data={data} />
    </Grid>
  );
}

export default Information;
