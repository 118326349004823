import * as React from "react";
import Typography from "@mui/material/Typography";

function Title(props) {
  const { data } = props;

  return (
    <Typography variant="h6" fontWeight="bold" gutterBottom sx={{ mb: 2 }}>
      {data}
    </Typography>
  );
}

export default Title;
