import * as React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

// Components
import Title from "../components/Title";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import Modules from "../components/list/Modules";

// Assets
import cmsImg from "../assets/info-cms.png";
import mapsImg from "../assets/info-maps.png";
import defaultImg from "../assets/card-header.png";
import operationalImg from "../assets/modules/operational.png";
import hrmImg from "../assets/modules/hrm.png";
import financeImg from "../assets/modules/finance.png";

const modulesPosts = [
  {
    id: 1,
    img: hrmImg,
    title: "Human Resource Management",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    link: "/hrm",
  },
  {
    id: 2,
    img: financeImg,
    title: "Keuangan & Asset",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    link: "/hrm",
  },
  {
    id: 3,
    img: operationalImg,
    title: "Operasional",
    date: "Nov 12",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    link: "/hrm",
  },
  {
    id: 4,
    img: defaultImg,
    title: "General",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    link: "/hrm",
  },
];

const sidebar = {
  description:
    "Etiam porta sem malesuada magna mollis euismod. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur.",
  information: [
    {
      id: 1,
      img: cmsImg,
      title: "Corporate Management System (CMS)",
      url: "#",
    },
    { id: 2, img: mapsImg, title: "Mapping System", url: "#" },
  ],
};

export default function Index() {
  return (
    <div>
      <Container maxWidth="xl">
        <Navbar />
        <main>
          <Header isLogin={false} />
          <Grid container spacing={4} sx={{ mt: 3 }}>
            <Grid item xs={12} md={4}>
              <Sidebar
                description={sidebar.description}
                information={sidebar.information}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Title data={"List Modul"} />
              <Grid container spacing={4}>
                {modulesPosts.map((post) => (
                  <Modules key={post.id} post={post} />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </main>
      </Container>
      <Footer />
    </div>
  );
}
