import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

// Components
import Title from "../../components/Title";
import Navbar from "../../components/Navbar";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Breadcrumbs from "../../components/Breadcrumbs";

// Sidebar
import Sidebar from "../../components/contents/Sidebar";
import Content from "../../components/contents/Contents";

export default function Detail() {
  const [collapsed, setCollapsed] = useState(false);

  const breadcrumbsData = [
    {
      id: 1,
      title: "Beranda",
      link: "/",
      isActive: false,
    },
    {
      id: 2,
      title: "Human Resource Management",
      link: "/hrm",
      isActive: false,
    },
    {
      id: 3,
      title: "Zona Anda",
      link: "/hrm/detail",
      isActive: true,
    },
  ];

  return (
    <div>
      <Container maxWidth="xl">
        <Navbar />
        <main>
          <Header isLogin={true} />
          <Grid container spacing={4} sx={{ mt: 2 }}>
            <Grid item xs={12} md={12}>
              <Breadcrumbs data={breadcrumbsData} />
              <Title data={"Zona Anda"} />
              <Grid container spacing={0.5} sx={{ mt: 4 }}>
                <Grid item xs={12} md={collapsed ? 0 : 3}>
                  <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
                </Grid>
                <Grid item xs={12} md={collapsed ? 12 : 9}>
                  <Content />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </main>
      </Container>
      <Footer />
    </div>
  );
}
