import * as React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// Assets
import headerImg from "../assets/header.png";

function Header(props) {
  const { isLogin } = props;

  return (
    <Paper
      sx={{
        position: "relative",
        color: "#fff",
        mb: 2,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url(${headerImg})`,
      }}
    >
      {/* Increase the priority of the hero background image */}
      {<img style={{ display: "none" }} src={headerImg} alt={""} />}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: "rgba(0,0,0,.2)",
        }}
      />
      <Grid container>
        <Grid item md={6}>
          <Box
            sx={{
              position: "relative",
              p: { xs: 3, md: 6 },
              pr: { md: 0 },
            }}
          >
            <Typography variant="h4" fontWeight="bold">
              Dokumentasi Sistem
            </Typography>
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              Perumda Parkir Makassar Raya
            </Typography>
            {isLogin ? (
              <Typography variant="subtitle1">
                Selamat Datang, Direktur Utama!
              </Typography>
            ) : (
              <Typography variant="subtitle1">
                Menyambut Era Digitalisasi, Perumda Parkir Makassar Raya
                Melakukan Transformasi Pelayanan Pengelolaan Parkir di Kota
                Makassar!
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Header;
