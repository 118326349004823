import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function ImgMediaCard(props) {
  const { data } = props;

  return (
    <Card
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
      }}
    >
      <CardMedia
        component="img"
        alt="green iguana"
        height="150"
        image={data?.img}
      />
      <CardContent>
        <Typography variant="subtitle1" fontWeight="bold">
          {data?.title}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          sx={{
            textTransform: "none",
          }}
          size="small"
        >
          Visit
        </Button>
      </CardActions>
    </Card>
  );
}
